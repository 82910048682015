import React from 'react';
import './Footer.css';

import logo from '../../assets/images/logo/logo_side_white.png';

import icon_instagram from '../../assets/icons/instagram_white.svg';
import icon_linkedin from '../../assets/icons/linkedin_white.svg';
import icon_discord from '../../assets/icons/discord_white.svg';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-line" />
            <div className="footer-content">
                <div className="footer-left">
                    <img className="footer-logo" src={logo} alt="Infinitus Interactive" />
                </div>
                <div className="footer-centre">
                    <div className="footer-text">
                        © 2024 Infinitus Interactive Ltd. All Rights Reserved. <br/>
                        The Infinitus logo is a trademark in the UK and/or other countries.
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-social-container">
                        <a href='https://www.instagram.com/infinitusinteractive/' target="_blank" rel="noopener noreferrer">
                            <img className="footer-social-icon" src={icon_instagram} alt="Instagram" />
                        </a>
                        <a href='https://www.linkedin.com/company/infinitusinteractive' target="_blank" rel="noopener noreferrer">
                            <img className="footer-social-icon" src={icon_linkedin} alt="LinkedIn" />
                        </a>
                        <a href='https://discord.gg/WCPCRdrKGZ' target="_blank" rel="noopener noreferrer">
                            <img className="footer-social-icon" src={icon_discord} alt="Discord" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;