import React from 'react';
import './Group.css';

export default ({ variant, title, icon, body, selected, previouslySelected, onClick }) => {
    const bodyClass = selected ? 'selected' : previouslySelected ? 'previouslySelected' : '';

    return (
        <div className="group" style={{ color: variant === 'dark' ? 'black' : 'white'}} onClick={onClick}>
            <div className="group-header">
                {icon && <img className="group-icon" src={icon} />}
                <div 
                    className={`group-title ${selected ? 'selected' : ''}`}
                    style={{ cursor: onClick ? 'pointer' : 'default'}}
                >
                    {title}
                </div>
                {/* <div className="group-title" onClick={() => onClick === null ? null : onClick()} style={{ opacity: selected ? '100%' : '50%', fontWeight: selected ? 'bold' : 'normal', cursor: onClick === null ? 'default' : 'pointer'}}>
                    {title}
                </div> */}
            </div>
            <div className={`group-body ${bodyClass}`}>
                {body}
            </div>
            {/* {selected && <div className="group-body">
                {body}
            </div>} */}
        </div>
    )
}