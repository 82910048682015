import React from 'react';

import Hero from './sections/Hero';
import Category from './sections/Category';

const DigitalTwins = [
    {
        Header: "Digital Twin",
        Link: "https://www.pipelinesentry.com/",
        Title: "PipelineSentry®",
        Body: "PipelineSentry® digitises and links all pipeline design, manufacturing, operations, and survey datasets - so operators can automate engineering assessments and make rapid data-driven maintenance decisions.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://www.youtube.com/playlist?list=PLxWegkT2PuTKy9vR20FSLsI_BEt_e5cKm",
    },
    {
        Header: "Digital Twin",
        Link: null,
        Title: "PlantSentry",
        Body: "PlantSentry is an integrated system designed to enhance operational efficiency of coating & insulation rectification works in an Oil & Gas setting.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://youtu.be/2vT-WNb0cDU",
    },
    {
        Header: "Digital Twin",
        Link: null,
        Title: "Asset Viewer",
        Body: "A demonstration digital twin to view subsea pipeline assets and associated data.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "image",
        FileData: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/digital-twins/asset-viewer/cover.png",
    }
]

const AugmentedReality = [
    {
        Header: "Augmented Reality Application",
        Link: null,
        Title: "Measure AR",
        Body: "Measure AR is a mobile measuring app for guiding the installation of customer purchases. The app features video streaming to an internal website where companies can communicate with their customers.",
        Platform: "mobile-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/mobile.png",
        FileType: "video",
        FileData: "https://youtube.com/shorts/k8Y0xDwGTfk",
    },
]

const VirtualReality = [
    {
        Header: "Virtual Reality Game",
        Link: null,
        Title: "Escape Reality",
        Body: "Wake up locked and confused in your bedroom. Solve puzzles and unravel the darkness of anxiety. Explore dark and personal themes in this expressive VR experience.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://youtu.be/6RT7daGw4Nk",
    },
]

const WebsitesGamesApps = [
    {
        Header: "Mobile Application",
        Link: "https://www.nomadity.app/",
        Title: "Nomadity",
        Body: "Embrace the freedom of the digital nomad lifestyle with Nomadity - your go-to platform for connectivity, exploration, and collaboration. Nomadity is a fusion of intuitive technology designed with the modern wanderer in mind.",
        Platform: "mobile-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/mobile.png",
        FileType: "image",
        FileData: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/website-game-app/nomadity/cover.png",
    },
    {
        Header: "Desktop & Mobile Game Series",
        Link: "https://infinitus-interactive.gitbook.io/ultra-series",
        Title: "ULTRA Series",
        Body: "The ULTRA series offers a rich variety of gaming experiences, blending intense combat, strategic survival challenges, stealth missions, and social interaction within a vibrant gaming ecosystem.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://www.youtube.com/playlist?list=PLxWegkT2PuTKp_bf8pIj-E0h0_SojvW7d",
    },
    {
        Header: "Desktop Game",
        Link: null,
        Title: "The Artefact",
        Body: "Your ship has crash landed on an alien planet. Gather resources and manage your team to survive. Explore character personalities withihn a classic retro-theme.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "image",
        FileData: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/website-game-app/the-artefact/cover.png",
    }
]

const AllProducts = [
    {
        Header: "Unity Asset",
        Link: "https://persona.infinitusinteractive.com/",
        Title: "Persona",
        Body: "Welcome to Persona, your essential tool for creating dynamic character profiles. Persona uses an advanced genetic algorithm to generate detailed character metadata, making each character unique and engaging. Ideal for RPGs, simulations, and narrative-driven games.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://www.youtube.com/playlist?list=PLxWegkT2PuTLCntEw99sIohKoQLGoixy0",
    },
    {
        Header: "Digital Twin",
        Link: "https://www.pipelinesentry.com/",
        Title: "PipelineSentry®",
        Body: "PipelineSentry® digitises and links all pipeline design, manufacturing, operations, and survey datasets - so operators can automate engineering assessments and make rapid data-driven maintenance decisions.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://www.youtube.com/playlist?list=PLxWegkT2PuTKy9vR20FSLsI_BEt_e5cKm",
    },
    {
        Header: "Digital Twin",
        Link: null,
        Title: "PlantSentry",
        Body: "PlantSentry is an integrated system designed to enhance operational efficiency of coating & insulation rectification works in an Oil & Gas setting.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://youtu.be/2vT-WNb0cDU",
    },
    {
        Header: "Digital Twin",
        Link: null,
        Title: "Asset Viewer",
        Body: "A demonstration digital twin to view subsea pipeline assets and associated data.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "image",
        FileData: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/digital-twins/asset-viewer/cover.png",
    },
    {
        Header: "Augmented Reality Application",
        Link: null,
        Title: "Measure AR",
        Body: "Measure AR is a mobile measuring app for guiding the installation of customer purchases. The app features video streaming to an internal website where companies can communicate with their customers.",
        Platform: "mobile-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/mobile.png",
        FileType: "video",
        FileData: "https://youtube.com/shorts/k8Y0xDwGTfk",
    },
    {
        Header: "Virtual Reality Game",
        Link: null,
        Title: "Escape Reality",
        Body: "Wake up locked and confused in your bedroom. Solve puzzles and unravel the darkness of anxiety. Explore dark and personal themes in this expressive VR experience.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://youtu.be/6RT7daGw4Nk",
    },
    {
        Header: "Mobile Application",
        Link: "https://www.nomadity.app/",
        Title: "Nomadity",
        Body: "Embrace the freedom of the digital nomad lifestyle with Nomadity - your go-to platform for connectivity, exploration, and collaboration. Nomadity is a fusion of intuitive technology designed with the modern wanderer in mind.",
        Platform: "none",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/mobile.png",
        FileType: "image",
        FileData: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/website-game-app/nomadity/mockup.png",
    },
    {
        Header: "Desktop & Mobile Game Series",
        Link: "https://infinitus-interactive.gitbook.io/ultra-series",
        Title: "ULTRA Series",
        Body: "The ULTRA series offers a rich variety of gaming experiences, blending intense combat, strategic survival challenges, stealth missions, and social interaction within a vibrant gaming ecosystem.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "video",
        FileData: "https://www.youtube.com/playlist?list=PLxWegkT2PuTKp_bf8pIj-E0h0_SojvW7d",
    },
    {
        Header: "Desktop Game",
        Link: null,
        Title: "The Artefact",
        Body: "Your ship has crash landed on an alien planet. Gather resources and manage your team to survive. Explore character personalities withihn a classic retro-theme. This project utilises the original TITAN system.",
        Platform: "desktop-device",
        Frame: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/frames/desktop.png",
        FileType: "image",
        FileData: "https://infinitusinteractive-products.s3.eu-west-2.amazonaws.com/website-game-app/the-artefact/cover.png",
    }
]

export default () => {
    return (
        <>
            <Hero />
            <Category title="Infinite Interactive Experiences" subtitle="Explore cutting-edge digital, augmented, and virtual realities" options={AllProducts} />
            {/* <Category id="digital-twin-products" title="Digital Twins" subtitle="Enhanced precision and real-time solutions" options={DigitalTwins} />
            <Category id="augmented-reality-products" title="Augmented Reality" subtitle="Blending the digital world with the physical" options={AugmentedReality} />
            <Category id="virtual-reality-products" title="Virtual Reality" subtitle="Unlock new realities with our immersive environments" options={VirtualReality} />
            <Category id="website-game-app-products" title="Websites, Games & Apps" subtitle="Building the future of digital engagement" options={WebsitesGamesApps} /> */}
        </>
    )
}