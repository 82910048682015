import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Splash.css';

import logo from '../../assets/images/logo/logo_side_white.png';

export default () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout (() => navigate('/services'), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="splash-content">
            <img className="splash-logo" src={logo} alt="Infinitus Interactive" />
        </div>
    )
}