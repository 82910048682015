import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

import Splash from './pages/splash/Splash';
import Services from './pages/services/Services';
import Products from './pages/products/Products';
import Product from './pages/products/pages/Product';
import Company from './pages/company/Company';
import Careers from './pages/careers/Careers';
import Connect from './pages/connect/Connect';
import Confirm from './pages/confirm/Confirm';

function Content() {
  const location = useLocation();
  const [showSplash, setShowSplash] = useState(() => {
    const hasBeenShown = localStorage.getItem('splashShown');
    return !hasBeenShown;
  });

  useEffect(() => {
    const isRoot = location.pathname === '/';
    if (isRoot) {
      setShowSplash(true);
    }
  }, [location]);

  useEffect(() => {
    if (showSplash) {
      const timer = setTimeout(() => {
        setShowSplash(false);
        localStorage.setItem('splashShown', 'true');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showSplash]);

  return (
    <>
      {showSplash ? (
        <Splash />
      ) : (
        <>
          <Navbar />
          <main>
            <Routes>
              <Route path="/services" element={<Services />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:productName" element={<Product />} />
              <Route path="/company" element={<Company />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/connect" element={<Connect />} />
              <Route path="/confirm" element={<Confirm />} />
            </Routes>
          </main>
          <Footer />
        </>
      )}
    </>
  )
}

function App() {
  return (
    <Router>
      <Content />
    </Router>
  )
}

export default App;
