import React, { useState } from 'react';
import './Company.css';

import Section01 from './sections/Section01';
import Section02 from './sections/Section02';
import Section03 from './sections/Section03';

export default () => {

    return (
        <div>
            <Section01 />
            <Section02 />
            <Section03 /> 
        </div>
    )
}