import React from 'react';
import './Section03.css';

import icon_1 from '../../../assets/icons/badge.svg';
import icon_2 from '../../../assets/icons/drop.svg';
import icon_3 from '../../../assets/icons/guage.svg';
import icon_4 from '../../../assets/icons/zap.svg';
import icon_5 from '../../../assets/icons/code.svg';
import icon_6 from '../../../assets/icons/tools.svg';

const groups = [
    {
        icon: icon_1,
        title: "Superior Quality",
        body: "Our team embodies professionalism at every stage, from initial concept to final product. We don’t just build software; we craft digital masterpieces tailored to your needs.",
    },
    {
        icon: icon_2,
        title: "Sophisticated Design",
        body: "Beauty and functionality go hand-in-hand in our designs. We’re dedicated to creating intuitive experiences that feel as good as they look — ensuring your users enjoy every interaction.",
    },
    {
        icon: icon_3,
        title: "Peak Performance",
        body: "Speed, reliability, and efficiency are the cornerstones of our development philosophy. Expect nothing less than the best, with software optimised for peak performance.",
    },
    {
        icon: icon_4,
        title: "Innovative Development",
        body: "Our agile development process is about more than speed. It’s about flexible, innovative solutions that bring your vision to life swiftly and effectively.",
    },
    {
        icon: icon_5,
        title: "Fresh Technology",
        body: "We harness the latest in technology to ensure your product isn’t just current — it’s ahead of its time. Stay relevant with software that’s built to lead trends, not follow them.",
    },
    {
        icon: icon_6,
        title: "Reliable Maintenance",
        body: "From launch to scale, our support is unwavering. As your product evolves, our team ensures it continues to enchant and perform — because your peace of mind is non-negotiable.",
    },
];

export default () => {
    return (
        <div className="background-gradient">
            <div className="background-pattern">
                <div className="desktop">
                    <div className="company-page-3-content">
                        {groups.map((group) => {
                            return (
                                <div className="company-page-3-group">
                                    <div className="company-page-3-group-header">
                                        <img className="company-page-3-group-header-icon" src={group.icon} />
                                        <div className="company-page-3-group-header-text">{group.title}</div>
                                    </div>
                                    <div className="company-page-3-group-body">{group.body}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="mobile">
                    <div className="company-page-3-content-mobile">
                        <div className="company-page-3-centre-content-mobile">
                            {groups.map((group) => {
                                return (
                                    <div className="company-page-3-grouped-content-mobile">
                                        <div className="company-page-3-grouped-content-header-mobile">
                                            <img className="company-page-3-group-header-icon" src={group.icon} />
                                            <div className="company-page-3-group-header-text">{group.title}</div>
                                        </div>
                                        <div className="company-page-3-grouped-content-body-mobile">{group.body}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}