import React, { useState } from 'react';
import './Navbar.css';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo/logo_side_black.png';
import dots from '../../assets/icons/dots-vertical.svg';
import cross from '../../assets/icons/cross.svg';

import icon_instagram from '../../assets/icons/instagram_black.svg';
import icon_linkedin from '../../assets/icons/linkedin_black.svg';
import icon_discord from '../../assets/icons/discord_black.svg';

const Navbar = () => {
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenuVisibility = (visible) => {
        setMenuVisible(visible);
        document.body.classList.toggle('no-scroll', visible);
    };

    return (
        <>
            <div className="desktop">
                <div className="navbar">
                    <div className="navbar-content">
                        <div className="navbar-left">
                            <img className="navbar-logo" src={logo} alt="Infinitus Interactive" />
                        </div>
                        <div className={`navbar-menu ${menuVisible ? 'show' : ''}`}>
                            <NavLink to="/services" className="navbar-item" activeClassName="active">Services</NavLink>
                            <NavLink to="/products" className="navbar-item" activeClassName="active">Products</NavLink>
                            <NavLink to="/company" className="navbar-item" activeClassName="active">Company</NavLink>
                            <NavLink to="/careers" className="navbar-item" activeClassName="active">Careers</NavLink>
                            <NavLink to="/connect" className="navbar-item" activeClassName="active">Connect</NavLink>
                        </div>
                        <div className="navbar-right">
                            <img className="navbar-menu-icon" onClick={() => setMenuVisible(!menuVisible)} src={dots} alt="Menu" />
                            <div className="navbar-social-container">
                                <a href='https://www.instagram.com/infinitusinteractive/' target="_blank" rel="noopener noreferrer">
                                    <img className="navbar-social-icon" src={icon_instagram} alt="Instagram" />
                                </a>
                                <a href='https://www.linkedin.com/company/infinitusinteractive' target="_blank" rel="noopener noreferrer">
                                    <img className="navbar-social-icon" src={icon_linkedin} alt="LinkedIn" />
                                </a>
                                <a href='https://discord.gg/WCPCRdrKGZ' target="_blank" rel="noopener noreferrer">
                                    <img className="navbar-social-icon" src={icon_discord} alt="Discord" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile">
                <div className="navbar-mobile">
                    <div className="navbar-mobile-top">
                        <div className="navbar-mobile-top-container">
                            <img className="navbar-mobile-top-logo" src={logo} alt="Infinitus Interactive" />
                            {!menuVisible && <img className="navbar-mobile-top-icon" onClick={() => toggleMenuVisibility(true)} src={dots} alt="Menu" />}
                            {menuVisible && <img className="navbar-mobile-top-icon" onClick={() => toggleMenuVisibility(false)} src={cross} alt="Menu" />}
                        </div>
                    </div>
                    {menuVisible && 
                        <div className="navbar-menu">
                            <div className="navbar-menu-items">
                                <NavLink to="/services" className="navbar-item" activeClassName="active" onClick={() => toggleMenuVisibility(false)}>Services</NavLink>
                                <NavLink to="/products" className="navbar-item" activeClassName="active" onClick={() => toggleMenuVisibility(false)}>Products</NavLink>
                                <NavLink to="/company" className="navbar-item" activeClassName="active" onClick={() => toggleMenuVisibility(false)}>Company</NavLink>
                                <NavLink to="/careers" className="navbar-item" activeClassName="active" onClick={() => toggleMenuVisibility(false)}>Careers</NavLink>
                                <NavLink to="/connect" className="navbar-item" activeClassName="active" onClick={() => toggleMenuVisibility(false)}>Connect</NavLink>
                            </div>
                            <div className="navbar-social-container">
                                <a href='https://www.instagram.com/infinitusinteractive/' target="_blank" rel="noopener noreferrer">
                                    <img className="navbar-social-icon" src={icon_instagram} alt="Instagram" />
                                </a>
                                <a href='https://www.linkedin.com/company/infinitusinteractive' target="_blank" rel="noopener noreferrer">
                                    <img className="navbar-social-icon" src={icon_linkedin} alt="LinkedIn" />
                                </a>
                                <a href='https://discord.gg/WCPCRdrKGZ' target="_blank" rel="noopener noreferrer">
                                    <img className="navbar-social-icon" src={icon_discord} alt="Discord" />
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Navbar;