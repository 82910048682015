import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './Product.css';

export default () => {
    const { productName } = useParams();
    const location = useLocation();
    const product = location.state && location.state.product;

    return (
        <div className="product-content">
            <div className="product-header">
                <div className="product-header-title">{product.Title}</div>
                <div className="product-header-subtitle">{product.Title}</div>
                <div className="product-header-line" />
            </div>
        </div>
    )
}