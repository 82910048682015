import React from 'react';
import './Section01.css';

import logo from '../../../assets/images/logo/logo.png';
import Group from '../../../components/group/Group';

export default () => {
    return (
        <div className="background-gradient">
            <div className="background-pattern">
                <div className="desktop">
                    <div className="company-page-1-content">
                        <div className="company-page-1-left-content">
                            <div className="company-page-1-grouped-container">
                                <div className="company-page-1-grouped-content">
                                    <span className="company-page-1-grouped-content-header">Our Tale</span>
                                    <span className="company-page-1-grouped-content-body">
                                        Launched in Scotland in 2020, Infinitus Interactive specialises in <b>crafting advanced solutions</b> for digital twins and augmented reality.
                                        <br /><br />
                                        Our foundation in video game development empowers us to lead with a <b>unique perspective</b>, consistently staying ahead of the curve.
                                    </span>
                                </div>
                                <div className="company-page-1-grouped-content">
                                    <span className="company-page-1-grouped-content-header">Our Vision</span>
                                    <span className="company-page-1-grouped-content-body">
                                        Having delivered <b>exceptional software</b> in key industries like Oil & Gas, Renewable Energy, and Telecommunications, we are committed to pushing the envelope further.
                                        <br /><br />
                                        Infinitus Interactive aims to not only maintain but also elevate industry standards; by continuously introducing ground-breaking technologies and <b>advancing the digital landscape</b>.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="company-page-1-right-content">
                            <img className="company-page-1-logo" src={logo}/>
                        </div>
                    </div>
                </div>
                <div className="mobile">
                    <div className="company-page-1-content-mobile">
                        <div className="company-page-1-centre-content-mobile">
                            <div className="company-page-1-grouped-content-mobile">
                                <span className="company-page-1-grouped-content-header-mobile">Our Tale</span>
                                <span className="company-page-1-grouped-content-body-mobile">
                                    Launched in Scotland in 2020, Infinitus Interactive specialises in <b>crafting advanced solutions</b> for digital twins and augmented reality.
                                    <br /><br />
                                    Our foundation in video game development empowers us to lead with a <b>unique perspective</b>, consistently staying ahead of the curve.
                                </span>
                            </div>
                            <div className="company-page-1-grouped-content-mobile">
                                <span className="company-page-1-grouped-content-header-mobile">Our Vision</span>
                                <span className="company-page-1-grouped-content-body-mobile">
                                    Having delivered <b>exceptional software</b> in key industries like Oil & Gas, Renewable Energy, and Telecommunications, we are committed to pushing the envelope further.
                                    <br /><br />
                                    Infinitus Interactive aims to not only maintain but also elevate industry standards; by continuously introducing ground-breaking technologies and <b>advancing the digital landscape</b>.
                                </span>
                            </div>
                        </div>
                        <div className="company-section-line" />
                    </div>
                </div>
            </div>
        </div>
    )
}