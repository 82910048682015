import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Category.css';

import icon_circle_arrow from '../../../assets/icons/circle_arrow.svg';

function renderImage(option) {
    const commonProps = { src: option.FileData, alt: option.Title };

    if (option.Platform === "none") {
        return (
            <div className="standalone-image">
                <img {...commonProps} />
            </div>
        );
    }

    return (
        <>
            <div className="desktop">
                <div className={`${option.Platform === "mobile-device" ? "products-category-product-showcase-frame-container" : ""}`}>
                    <img className={`products-category-product-showcase-frame ${option.Platform}`} src={option.Frame} />
                    <img {...commonProps} className={`products-category-product-showcase-screen ${option.Platform}`} />
                </div>
            </div>
            <div className="mobile">
                <div className={`${option.Platform === "mobile-device" ? "products-category-product-item-showcase-frame-container-mobile" : ""}`}>
                    <img className={`products-category-product-item-showcase-frame-mobile ${option.Platform}`} src={option.Frame} />
                    <img {...commonProps} className={`products-category-product-item-showcase-screen-mobile ${option.Platform}`} />
                </div>
            </div>
        </>
    );
};

function renderVideo(option) {
    // const videoID = option.FileData.split('/').pop();
    // const youtubeEmbedLink = `https://www.youtube.com/embed/${videoID}?autoplay=1&loop=1&mute=1`;
    
    // Extract the last part of the URL to determine if it's a playlist or a single video.
    const urlParts = option.FileData.split('/');
    const lastPart = urlParts.pop(); // Get the last segment of the URL.
    let youtubeEmbedLink;

    // Check if the last part starts with 'playlist', indicating it's a playlist URL.
    if (lastPart.startsWith('playlist?list=')) {
        const playlistId = lastPart.split('=').pop(); // Extract the playlist ID.
        youtubeEmbedLink = `https://www.youtube.com/embed/videoseries?list=${playlistId}&autoplay=0&loop=1&mute=1`; // Playlist embed link
    } else {
        // Standard video embedding for single videos.
        youtubeEmbedLink = `https://www.youtube.com/embed/${lastPart}?autoplay=0&loop=1&mute=1`;
    }

    return (
        <>
            <div className="desktop">
                <div className={`${option.Platform === "mobile-device" ? "products-category-product-showcase-frame-container" : ""}`}>
                    <img className={`products-category-product-showcase-frame ${option.Platform}`} src={option.Frame} />
                    <iframe
                        className={`products-category-product-showcase-screen ${option.Platform}`}
                        src={youtubeEmbedLink}
                        frameBorder="0"
                        allow="autoplay; loop; encrypted-media"
                        allowFullScreen
                        title={option.Title}
                    ></iframe>
                </div>
            </div>
            <div className="mobile">
                <div className={`${option.Platform === "mobile-device" ? "products-category-product-item-showcase-frame-container-mobile" : ""}`}>
                    <img className={`products-category-product-item-showcase-frame-mobile ${option.Platform}`} src={option.Frame} />
                    <iframe
                        className={`products-category-product-item-showcase-screen-mobile ${option.Platform}`}
                        src={youtubeEmbedLink}
                        frameBorder="0"
                        allow="autoplay; loop; encrypted-media"
                        allowFullScreen
                        title={option.Title}
                    ></iframe>
                </div>
            </div>
        </>
    )
};

const isInternalLink = (url) => {
    return url && !url.startsWith('http') && !url.startsWith('www');
};

export default ({ title, subtitle, options }) => {
    const navigate = useNavigate();    

    return (
        <>
            <div className="desktop">
                <div className="products-category-content">
                    <div className="line-split top" />
                    <div className="line-split bottom" />
                    <div className="products-category-header">
                        <div className="products-category-header-title">{title}</div>
                        <div className="products-category-header-subtitle">{subtitle}</div>
                        <div className="products-category-header-line" />
                    </div>
                    <div className="products-category-body">
                        {options.map(option => {
                            return <>
                                <div className="products-category-product">
                                    <div className="products-category-product-showcase">
                                        {option.FileType === "image" && renderImage(option)}
                                        {option.FileType === "video" && renderVideo(option)}
                                    </div>
                                    <div className="products-category-product-line" />
                                    <div className="products-category-product-header">
                                        <div className="products-category-product-header-text">{option.Header}</div>
                                        {option.Link !== null && <>
                                            {isInternalLink(option.Link) ? <>
                                                <Link to={{ pathname: option.Link, state: { product: option } }} style={{ textDecoration: 'none' }}>
                                                    <div className="products-category-product-header-link">
                                                        <div className="products-category-product-header-link-text">Discover more</div>
                                                        <img className="products-category-product-header-link-icon" src={icon_circle_arrow} />
                                                    </div>
                                                </Link>
                                            </> : <>
                                                <a href={option.Link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                    <div className="products-category-product-header-link">
                                                        <div className="products-category-product-header-link-text">Discover more</div>
                                                        <img className="products-category-product-header-link-icon" src={icon_circle_arrow} />
                                                    </div>
                                                </a>
                                            </>}
                                        </>}
                                    </div>  
                                    <div className="products-category-product-title">{option.Title}</div>
                                    <div className="products-category-product-body">{option.Body}</div>                           
                                    <button className="button" onClick={() => navigate('/connect')}>Request Solution</button>
                                </div>
                            </>
                        })}
                    </div>
                </div>
            </div>
            <div className="mobile">
                {/* <div className="background-gradient"> */}
                    {/* <div className="background-pattern"> */}
                        <div className="line-split top" />
                        <div className="products-category-content-mobile">
                            <div className="products-category-body-mobile">
                                <div className="products-category-section-header-mobile">
                                    <div className="products-category-section-header-content-mobile">
                                        <div className="products-category-section-header-title-mobile">{title}</div>
                                        <div className="products-category-section-header-subtitle-mobile">{subtitle}</div>
                                        <div className="products-category-section-header-line-mobile" />
                                    </div>
                                </div>
                                {options.map(option => {
                                    return <>
                                        <div className="products-category-section-item-mobile">
                                            <div className="products-category-section-item-content-mobile">
                                                <div className="products-category-product-item-showcase-mobile">
                                                    {option.FileType === "image" && renderImage(option)}
                                                    {option.FileType === "video" && renderVideo(option)}
                                                </div>
                                                <div className="products-category-section-item-line-mobile" />
                                                <div className="products-category-section-item-header-mobile">
                                                    <div className="products-category-section-item-header-tag-mobile">{option.Header}</div>
                                                    {option.Link !== null && <>
                                                        {isInternalLink(option.Link) ? <>
                                                            <Link to={{ pathname: option.Link, state: { product: option } }} style={{ textDecoration: 'none' }}>
                                                                <div className="products-category-section-item-header-link-mobile">
                                                                    <div className="products-category-section-item-header-link-text-mobile">Discover more</div>
                                                                    <img className="products-category-section-item-header-link-icon-mobile" src={icon_circle_arrow} />
                                                                </div>
                                                            </Link>
                                                        </> : <>
                                                            <a href={option.Link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                                <div className="products-category-section-item-header-link-mobile">
                                                                    <div className="products-category-section-item-header-link-text-mobile">Discover more</div>
                                                                    <img className="products-category-section-item-header-link-icon-mobile" src={icon_circle_arrow} />
                                                                </div>
                                                            </a>
                                                        </>}
                                                    </>}
                                                </div>
                                                <div className="products-category-section-item-title-mobile">{option.Title}</div>
                                                <div className="products-category-section-item-body-mobile">{option.Body}</div>
                                                <button className="button" onClick={() => navigate('/connect')}>Request Solution</button>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </div>
        </>
    )
}