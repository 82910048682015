import React, { useState } from 'react';
import './Services.css';

import Group from '../../components/group/Group';

import image_designrush from '../../assets/images/other/designrush.png';

const options = [
    { key: 'DIGITAL_TWINS', label: 'Digital Twins', body: 'Bringing precision and interactivity to your virtual models with our cutting-edge digital twin technology. Experience seamless integration with real-world data for dynamic situations.' },
    { key: 'AUGMENTED_REALITY', label: 'Augmented Reality', body: 'Elevate your user experience with immersive AR solutions. Our expertise in game development infuses unparalleled creativity into applications that redefine reality.' },
    { key: 'VIRTUAL_REALITY', label: 'Virtual Reality', body: 'Step into new worlds with our VR services. We craft compelling virtual environments for training, simulations, and interactive entertainment.' },
    { key: 'WEBSITES_GAMES_APPS', label: 'Websites, Games & Apps', body: 'From concept to code, our versatile team builds responsive websites, engaging games, and robust applications tailored to your vision.' },
    { key: 'ASSETS', label: 'Unity Assets', body: 'Expand your development toolkit with our high-quality assets. Designed for seamless integration, our assets empower developers to enhance their projects with minimal effort.' }
];

const Services = () => {
    const [selected, setSelected] = useState(options[0].key);

    const handleGroupClick = (key) => {
        setSelected(key);
    };

    return (
        <>
            <div className="desktop">
                <div className="background-gradient">
                    <div className="background-pattern">
                        <div className="services-content">
                            <div className="services-left-content">
                                <div className="services-grouped-content">
                                    <span className="services-grouped-content-header">
                                        {options.find(option => option.key === selected).label}
                                    </span>
                                    <span className="services-grouped-content-body">
                                        {options.find(option => option.key === selected).body}
                                    </span>
                                </div>
                            </div>
                            <div className="services-right-container">
                                <div className="line-split vertical browser" />
                                <div className="services-right-content">
                                    <div className="services-options">
                                        {options.map(option => (
                                            <span
                                                key={option.key}
                                                className={`services-options-header ${selected === option.key ? 'selected' : ''}`}
                                                onClick={() => setSelected(option.key)}
                                            >
                                                {option.label}
                                            </span>
                                        ))}
                                        <a href="https://www.designrush.com/agency/profile/infinitus-interactive" target="_blank" rel="noopener noreferrer">
                                            <img src={image_designrush} alt="DesignRush" className="verification-image" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="mobile">
                <div className="background-gradient">
                    <div className="background-pattern">
                        <div className="services-content">
                            <div className="services-centre-content">
                                <div className="services-grouped-content">
                                    {options.map(option => (
                                        <Group
                                            key={option.key}
                                            variant={"light"}
                                            title={option.label}
                                            body={option.body}
                                            selected={selected === option.key}
                                            onClick={() => handleGroupClick(option.key)}
                                        />
                                    ))}
                                    <a href="https://www.designrush.com/agency/profile/infinitus-interactive" target="_blank" rel="noopener noreferrer">
                                        <img src={image_designrush} alt="DesignRush" className="verification-image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;