import React, { useState } from 'react';
import './Section02.css';

import linkedin from '../../../assets/icons/linkedin_colour.svg';
import team_dylan from '../../../assets/images/team/dylan.png';
import team_adam from '../../../assets/images/team/adam.png';
import team_coco from '../../../assets/images/team/coco.png';
import team_corey from '../../../assets/images/team/corey.png';
import team_lewys from '../../../assets/images/team/lewys.png';

const Option = {
    DYLAN: "Dylan",
    ADAM: "Adam",
    COCO: "Coco",
    COREY: "Corey",
    // LEWYS: "Lewys",
}

const teamMembers = [
    {
        option: Option.DYLAN,
        image: team_dylan,
        social: "https://www.linkedin.com/in/dylaninfinitus/",
        level: "Chief Visionary",
        xp: "11 years",
        origin: "Founding Spirit, 2020",
        classes: "Strategic Foreseer, Creative Wizard, Technological Oracle",
        accolades: "",
        blurb: "Leading Infinitus Interactive with a blend of creative vision and technical prowess, Dylan drives the team towards innovative frontiers in digital reality."
    },
    {
        option: Option.ADAM,
        image: team_adam,
        social: "https://www.linkedin.com/in/adam-stanners-26a69b1aa/",
        level: "Tech Virtuoso",
        xp: "9 years",
        origin: "The Code Architect, 2022",
        classes: "Mechanical Guru, Neural Networker, Graphical Maestro",
        accolades: "",
        blurb: "Adam weaves the fabric of virtual worlds with a masterful blend of AI and graphics programming, creating immersive experiences that captivate and engage."
    },
    {
        option: Option.COCO,
        image: team_coco,
        social: null,
        level: "Narrative Strategist",
        xp: "6 years",
        origin: "A Brand Odyssey, 2023",
        classes: "Campaign Conjurer, Digital Storyteller, Audience Engager",
        accolades: "",
        blurb: "Coco orchestrates our brand's symphony, harmonising market trends with storytelling finesse to resonate deeply within our community and beyond."
    },
    {
        option: Option.COREY,
        image: team_corey,
        social: null,
        level: "Digital Craftsman",
        xp: "4 years",
        origin: "The Digital Pathfinder, 2023",
        classes: ["Web Painter, Backend Guardian,", "Systematic Champion"],
        accolades: "",
        blurb: "Corey's magic lies in creating intuitive and robust applications, ensuring a seamless journey from backend to frontend."
    },
    // {
    //     option: Option.LEWYS,
    //     image: team_lewys,
    //     social: null,
    //     level: "Prodigy",
    //     xp: "N/A",
    //     origin: "",
    //     classes: "",
    //     accolades: "",
    //     blurb: ""
    // },
]

export default () => {
    const [selected, setSelected] = useState(Option.DYLAN);

    return (
        <>
            <div className="desktop">
                <div className="company-page-2-content">
                    <div className="line-split top" />
                    <div className="line-split bottom" />
                    <div className="company-page-2-header">
                        <div className="company-page-2-header-title">The pioneers behind the pixels</div>
                        <div className="company-page-2-header-subtitle">Our executive team is comprised of industry professionals, each with unique skills and a shared passion for creating advanced digital experiences.</div>
                    </div>
                    <div className="company-page-2-team">
                        <div className={`company-page-2-team-option ${selected === Option.DYLAN ? 'selected' : ''}`} onClick={() => setSelected(Option.DYLAN)}>{Option.DYLAN}</div>
                        <div className={`company-page-2-team-option ${selected === Option.ADAM ? 'selected' : ''}`} onClick={() => setSelected(Option.ADAM)}>{Option.ADAM}</div>
                        <div className={`company-page-2-team-option ${selected === Option.COCO ? 'selected' : ''}`} onClick={() => setSelected(Option.COCO)}>{Option.COCO}</div>
                        <div className={`company-page-2-team-option ${selected === Option.COREY ? 'selected' : ''}`} onClick={() => setSelected(Option.COREY)}>{Option.COREY}</div>
                        {/* <div className={`company-page-2-team-option ${selected === Option.LEWYS ? 'selected' : ''}`} onClick={() => setSelected(Option.LEWYS)}>{Option.LEWYS}</div> */}
                    </div>
                    {teamMembers.map(member => 
                        selected === member.option && (
                            <div className="company-page-2-card" key={member.option}>
                                
                                <div className="company-page-2-card-background">
                                    <img className="company-page-2-card-image" src={member.image} alt={member.name} />
                                    <div className="company-page-2-card-content">
                                        <div className="company-page-2-card-content-group">
                                            <div className="company-page-2-card-content-group-header">
                                                <div className="company-page-2-card-content-group-header-text">{member.option}</div>
                                                {member.social !== null && (
                                                    <a href={member.social} target="_blank" rel="noopener noreferrer">
                                                        <img className="company-page-2-card-content-group-header-icon" src={linkedin} alt="LinkedIn" />
                                                    </a>
                                                )}
                                            </div>
                                            <div className="company-page-2-card-content-group-body">
                                                <div className="company-page-2-card-content-group-body-left">
                                                    <b>Level</b><br/>{member.level}<br/><br/>
                                                    <b>Experience Points</b><br/>{member.xp}<br/><br/>
                                                    <b>Origin Quest</b><br/>{member.origin}
                                                </div>
                                                <div className="company-page-2-card-content-group-body-right">
                                                    <b>Classes</b><br/>
                                                    {
                                                        Array.isArray(member.classes) ?
                                                            member.classes.map((cls, index) => (
                                                                <React.Fragment key={index}>
                                                                    {cls}{index < member.classes.length - 1 ? <br /> : null}
                                                                </React.Fragment>
                                                            ))
                                                        : member.classes
                                                    }
                                                    <br/><br/>
                                                    {/* <b>Accolades</b><br/>{member.accolades} */}
                                                </div>
                                            </div>
                                            <div className="company-page-2-card-content-group-body-bottom">
                                                {member.blurb}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className="mobile">
                <div className="company-page-2-content-mobile">
                    <div className="line-split top" />
                    <div className="line-split bottom" />
                    <div className="company-page-2-header-mobile">
                        <div className="company-page-2-header-title-mobile">Pioneers behind the pixels</div>
                        <div className="company-page-2-header-subtitle-mobile">Our executive team is comprised of industry professionals, each with unique skills and a shared passion for creating advanced digital experiences.</div>
                    </div>
                    <div className="products-category-section-header-line-mobile" />
                    <div className="company-page-2-team-mobile">
                        <div className={`company-page-2-team-option-mobile ${selected === Option.DYLAN ? 'selected' : ''}`} onClick={() => setSelected(Option.DYLAN)}>{Option.DYLAN}</div>
                        <div className={`company-page-2-team-option-mobile ${selected === Option.ADAM ? 'selected' : ''}`} onClick={() => setSelected(Option.ADAM)}>{Option.ADAM}</div>
                        <div className={`company-page-2-team-option-mobile ${selected === Option.COCO ? 'selected' : ''}`} onClick={() => setSelected(Option.COCO)}>{Option.COCO}</div>
                        <div className={`company-page-2-team-option-mobile ${selected === Option.COREY ? 'selected' : ''}`} onClick={() => setSelected(Option.COREY)}>{Option.COREY}</div>
                        {/* <div className={`company-page-2-team-option-mobile ${selected === Option.LEWYS ? 'selected' : ''}`} onClick={() => setSelected(Option.LEWYS)}>{Option.LEWYS}</div> */}
                    </div>
                    {teamMembers.map(member => 
                        selected === member.option && (
                            <div className="company-page-2-card-content-mobile" key={member.option}>
                                <img className="company-page-2-card-image-mobile" src={member.image} alt={member.name} />
                                <div className="company-page-2-card-content-group-mobile">
                                    <div className="company-page-2-card-content-group-body-mobile">
                                        <b>Level</b><br/>{member.level}<br/><br/>
                                        <b>Experience Points</b><br/>{member.xp}<br/><br/>
                                        <b>Origin Quest</b><br/>{member.origin}<br/><br/>
                                        <b>Classes</b><br/>
                                        {
                                            Array.isArray(member.classes) ?
                                                member.classes.map((cls, index) => (
                                                    <React.Fragment key={index}>
                                                        {cls}{index < member.classes.length - 1 ? <br /> : null}
                                                    </React.Fragment>
                                                ))
                                            : member.classes
                                        }
                                        <br/><br/>
                                        {member.blurb}<br/><br/>
                                        {member.social !== null && (
                                            <a href={member.social} target="_blank" rel="noopener noreferrer">
                                                <img className="company-page-2-card-content-group-header-icon" src={linkedin} alt="LinkedIn" />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                    <div className="company-section-line" />
                </div>
            </div>
        </>
    )
}