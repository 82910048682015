import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './Connect.css';

export default () => {
    const form = useRef();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({ name: '', email: '', message: ''});

    function validateFields() {
        const newErrors = { name: '', email: '', message: '' };
        const formElements = form.current;
        if (!formElements.name.value) {
            newErrors.name = 'NAME IS REQUIRED';
        }
        if (!formElements.email.value) {
            newErrors.email = 'EMAIL IS REQUIRED';
        } else if (!/\S+@\S+\.\S+/.test(formElements.email.value)) {
            newErrors.email = 'PLEASE ENTER A VALID EMAIL ADDRESS';
        }
        if (!formElements.message.value) {
            newErrors.message = 'MESSAGE IS REQUIRED';
        }
        return newErrors;
    }

    function sendEmail(e) {
        e.preventDefault();
        const newErrors = validateFields();
        if (!newErrors.name && !newErrors.email && !newErrors.message) {
            emailjs.sendForm(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_USER_ID
            ).then((result) => {
                console.log("Message is sent.");
                navigate('/confirm');
            }, (error) => {
                console.log(error.text);
            });
        }
        else {
            setErrors(newErrors);
        }        
    }

    return (
        <>
            <div className="desktop">
                <div className="background-gradient">
                    <div className="background-pattern">
                        <div className="connect-content">
                            <div className="connect-container">
                                <div className="line-split vertical" />
                                <div className="connect-form-group">
                                    <div className="connect-form-header">
                                        <div className="connect-form-header-title">Connect with us</div>
                                        <div className="connect-form-header-subtitle">Let's create something great together</div>
                                    </div>
                                    <form className="connect-form" method="post" ref={form} onSubmit={sendEmail}>
                                        <div className="connect-form-field">
                                            <div className="connect-form-field-header">
                                                <div className="connect-form-field-title">MY NAME IS</div>
                                                {errors.name && <div className="connect-form-field-error">{errors.name}</div>}
                                            </div>
                                            <input className="connect-form-field-input" name="name" />
                                        </div>
                                        <div className="connect-form-field">
                                            <div className="connect-form-field-header">
                                                <div className="connect-form-field-title">MY EMAIL IS</div>
                                                {errors.email && <div className="connect-form-field-error">{errors.email}</div>}
                                            </div>
                                            <input className="connect-form-field-input" name="email" />
                                        </div>
                                        <div className="connect-form-field">
                                            <div className="connect-form-field-header">
                                                <div className="connect-form-field-title">MY MESSAGE IS</div>
                                                {errors.message && <div className="connect-form-field-error">{errors.message}</div>}
                                            </div>
                                            <textarea className="connect-form-field-input" name="message" rows={3} resize="vertical" />
                                        </div>
                                        <div className="connect-form-button-container">
                                            <button type="submit" className="button">Send Message</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="line-split vertical" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile">
                <div className="connect-content-mobile">
                    <div className="connect-form-group-mobile">
                        <div className="connect-form-header-mobile">
                            <div className="connect-form-header-title-mobile">Connect with us</div>
                            <div className="connect-form-header-subtitle-mobile">Let's create something great together</div>
                            <div className="connect-form-header-line-mobile" />
                        </div>
                        <form className="connect-form-mobile" method="post" ref={form} onSubmit={sendEmail}>
                            <div className="connect-form-field">
                                <div className="connect-form-field-header">
                                    <div className="connect-form-field-title">MY NAME IS</div>
                                    {errors.name && <div className="connect-form-field-error">{errors.name}</div>}
                                </div>
                                <input className="connect-form-field-input" name="name" />
                            </div>
                            <div className="connect-form-field">
                                <div className="connect-form-field-header">
                                    <div className="connect-form-field-title">MY EMAIL IS</div>
                                    {errors.email && <div className="connect-form-field-error">{errors.email}</div>}
                                </div>
                                <input className="connect-form-field-input" name="email" />
                            </div>
                            <div className="connect-form-field">
                                <div className="connect-form-field-header">
                                    <div className="connect-form-field-title">MY MESSAGE IS</div>
                                    {errors.message && <div className="connect-form-field-error">{errors.message}</div>}
                                </div>
                                <textarea className="connect-form-field-input" name="message" rows={3} resize="vertical" />
                            </div>
                            <div className="connect-form-button-container">
                                <button type="submit" className="button">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}