import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Careers.css';

import icon_circle_arrow from '../../assets/icons/circle_arrow.svg';

const roles = [
    {
        title: "Unity Developer",
        body: "Join our team as a Unity Developer and harness the Unity engine to craft immersive digital experiences. Collaborate on innovative projects, turning creative concepts into interactive realities.",
        type: "CONTRACT",
        location: "REMOTE",
    },
    {
        title: "Backend Engineer",
        body: "Join us as a Backend Engineer to power the core of our digital solutions. You’ll develop robust, scalable backend systems, integrate APIs, and manage databases, contributing to the backbone of our innovative applications.",
        type: "CONTRACT",
        location: "REMOTE"
    },
    {
        title: "Web Developer",
        body: "Step into the role of Web Developer with us, where you’ll bring websites to life through elegant code and innovative design. In this position, you’ll collaborate on creating responsive, user-friendly web experiences, ensuring high performance and integrating cutting-edge technologies.",
        type: "CONTRACT",
        location: "REMOTE"
    },
    {
        title: "Digital Marketer",
        body: "Dive into our dynamic team as a Digital Marketer where you’ll strategise and execute campaigns that amplify our digital presence. Utilise your analytical skills and creativity to drive engagement, enhance brand visibility, and measure performance.",
        type: "CONTRACT",
        location: "REMOTE"
    }
];

export default () => {
    const navigate = useNavigate();

    return (
        <div className="background-gradient">
            <div className="background-pattern">
                <div className="desktop">
                    <div className="careers-content">
                        <div className="careers-header">
                            <div className="careers-header-title">The developers of tomorrow</div>
                            <div className="careers-header-subtitle">Begin your journey with <b>team infinitus</b> today</div>
                        </div>
                        <div className="careers-container">
                            {roles.map(role => {
                                return (
                                    <div className="careers-group">
                                        <div className="careers-group-line" />
                                        <div className="careers-group-header">
                                            <div className="careers-group-header-left">
                                                <div className="careers-group-header-text">{role.type} | {role.location}</div>
                                            </div>
                                            <div className="careers-group-header-right" onClick={() => navigate('/connect')}>
                                                <div className="careers-group-header-text">APPLY NOW</div>
                                                <img className="careers-group-header-icon" src={icon_circle_arrow} />
                                            </div>
                                        </div>
                                        <div className="careers-group-title">{role.title}</div>
                                        <div className="careers-group-body">{role.body}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="mobile">
                    <div className="careers-content-mobile">
                        <div className="careers-header-mobile">
                            <div className="careers-header-content-mobile">
                                <div className="careers-header-title-mobile">The developers of tomorrow</div>
                                <div className="careers-header-subtitle-mobile">Begin your journey with <b>team infinitus</b> today</div>
                                {/* <div className="careers-header-line-mobile" /> */}
                            </div>
                            <div className="careers-container-mobile">
                                {roles.map(role => {
                                    return (
                                        <div className="careers-group-mobile">
                                            <div className="careers-group-line-mobile" />
                                            <div className="careers-group-header-mobile">
                                                <div className="careers-group-header-left-mobile">
                                                    <div className="careers-group-header-text-mobile">{role.type} | {role.location}</div>
                                                </div>
                                                <div className="careers-group-header-right-mobile" onClick={() => navigate('/connect')}>
                                                    <div className="careers-group-header-text-mobile">APPLY NOW</div>
                                                    <img className="careers-group-header-icon-mobile" src={icon_circle_arrow} />
                                                </div>
                                            </div>
                                            <div className="careers-group-title-mobile">{role.title}</div>
                                            <div className="careers-group-body-mobile">{role.body}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}