import React from 'react';
import './Hero.css';

import logo from '../../../assets/images/logo/logo_cut.png';

export default () => {
    return (
        <>
            <div className="desktop">
                <div className="background-gradient">
                    <div className="background-pattern">
                        <div className="products-hero-content">
                            <div className="products-hero-header">
                                <div className="products-hero-header-title">Tailored digital creations, inspired by success</div>
                                <div className="products-hero-header-subtitle">Discover how we transform challenges into innovative solutions</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile">
                <div className="products-hero-content">
                    <div className="products-hero-header">
                        <div className="products-hero-header-container">
                            <div className="products-hero-header-title">Tailored digital creations, inspired by success</div>
                            <div className="products-hero-header-subtitle">Discover how we transform challenges into innovative solutions</div>
                        </div>
                        <div className="products-hero-logo-container">
                            <img className="products-hero-logo" src={logo}/>
                        </div>
                    </div>
                    <div className="products-hero-line" />
                </div>
            </div>
        </>
    )
}