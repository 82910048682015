import React from 'react';
import './Confirm.css';

import logo from '../../assets/images/logo/logo.png';

export default () => {
    return (
        <div className="background-gradient">
            <div className="background-pattern">
                <div className="confirm-content">
                    <div className="confirm-container">
                        <div className="confirm-line" />
                        <div className="confirm-header">
                            <img className="confirm-logo" src={logo}/>
                            <div className="confirm-header-title">Thanks for connecting</div>
                            <div className="confirm-header-subtitle">A member of our team will get back to you soon</div>
                        </div>
                        <div className="confirm-line" />
                    </div>
                </div>
            </div>
        </div>
    )
}